'use client'
import Status from '@/components/GeneralComponents/Problem/Status'
import { IErrorFallbackProps } from '@/config/ErrorBoundary'
import { Button } from '@umahealth/occipital'

export default function LoginErrorPage({ error, resetErrorBoundary }: IErrorFallbackProps){
	return <div>
		<Status
			type='warning'
			title='Hubo un error en la aplicación'
			subtitle={` error: ${error?.name} ${error?.message} ${error?.stack}, Podés comunicarte a info@uma-health.com`}
			report={false}
		>
			<>
				<Button
					action={() => resetErrorBoundary()}
					size="full"
					type='button'
					variant='filled'
					className='mb-3'
				>
						Intentar nuevamente
				</Button>
				<Button
					action={() => location.href = '/'}
					size="full"
					variant='outlined'
					type='button'
				>
						Volver al inicio
				</Button>
			</>
		</Status>
	</div>
}